import { Divider } from '@mui/material'
import axios from 'axios'
import Modal from 'Components/Modal/modal'
import { baseURL } from 'Configs/axios'
import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

const Landscapedesign = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const [meta, setMeta] = useState()
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  const [faq, setFaq] = useState();

  const getFaq = () => {
    axios
      .get(`https://m1blog.aaragroups.com/blog/faq-api/`, {
        headers: {
          /* Authorization: `Token ${localStorage.getItem("token")}`, */
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          page_id: "17",
        },
      })
      .then((res) => {
        setFaq(res.data?.data);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };
  useEffect(() => {
    getFaq();
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  const getMeta = () => {
    axios
      .get(`${baseURL}/blog/meta-api/?page_title=${17}`, {
        headers: {
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
        console.log(res?.data?.data);
      })

  };
  useEffect(() => {
    getMeta();

  }, []);

  return (
    <div className="flex items-center justify-center bg-white ">
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (<Helmet>
        <title>{meta.meta_title}</title>
        <meta name="title" content={metaItem?.meta_title || 'Default Title'} />
        <meta name="description" content={metaItem?.meta_description || 'Default description'} />
        <meta name="meta_tag" content={metaItem?.meta_tag || 'Default Tag'} />
        <meta name="meta_keyword" content={metaItem?.meta_keyword || 'Default Keyword'} />
        <meta property="og:title" content={metaItem?.og_title || 'Default Title'} />
        <meta property="og:description" content={metaItem?.og_description || 'Default description'} />
        <link rel='canonical' href={metaItem?.canonical} />
        <meta name="og_type" content={metaItem?.og_type || 'Default type'} />
        <meta name="og_url" content={metaItem?.og_url || 'Default description'} />
        <meta name="or_sitename" content={metaItem?.or_sitename || 'Default Tag'} />
        <meta name="twitter_card" content={metaItem?.twitter_card || 'Default card'} />
        <meta property="twitter_description" content={metaItem?.twitter_description || 'Default Title'} />
        <meta property="twitter_image" content={metaItem?.twitter_image || 'Default description'} />
        <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>

      </Helmet>))}

      <div className="container flex flex-col p-4 text-lg bg-white lg:justify-center">
        <div className="flex flex-col gap-10 pt-2 bg-white md:flex-row lg:flex-row lg:justify-center lg:items-center lg:pt-10">
          <iframe
            src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
            frameborder="0"
          ></iframe>
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-2 justify-end lg:pt-0 md:pt-24">
            <p className="text-2xl font-bold text-black md:text-3xl lg:text-4xl">
              Landscape design services in Noida
            </p>

            <p className="text-[#878787] text-lg ">
              Ashirwadum design & architects have significant hands-on experience in delivering
              urban planning projects as we have been serving this industry for a very long time. It
              includes Zonal planning, regional planning, Infrastructure planning, Master planning
              and special area development planning.
            </p>
            <p className="text-[#878787] text-lg ">
              Lastly, we ensure that our customers are delighted with our work.{' '}
            </p>
            <p className="text-[#878787] text-lg ">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex flex-wrap gap-2">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">VIDEO | </p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center gap-5 bg-white">
          <div className="container grid grid-cols-2 gap-4 pt-5 lg:grid-cols-4 md:grid-cols-4 lg:pt-14 md:pt-10">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic1.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic1.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic2.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic2.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic3.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic3.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic4.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic4.jpg'
                )
              }
            />
          </div>
        </div>
        <div className="container grid gap-5 pt-5 md:grid-cols-2 lg:pt-20 md:pt-10 ">
          <div className="flex flex-col md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15%]  gap-10 ">
              <p className="text-2xl font-bold text-black md:text-4xl">
                Modern
                <br />
                Landscape Design
              </p>
            </div>
          </div>
          <div className="flex flex-col text-[#878787]  ">
            <p className="text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%] w-[75%]">
              Modern landscape design for farmhouses & villas Architecture + Interior Design
            </p>

            <p className="lg:w-[95%] md:w-[95%] md:text-xl text-lg">
              Step into a realm where modern design seamlessly blurs the boundaries between indoors
              and nature, ushering in a truly holistic living experience.
            </p>
            <p className="text-lg md:text-xl">
              Plants are selected for their structural qualities and year-round appeal rather than
              just for seasonal color, often arranged in thoughtful, deliberate groupings. Water
              features, like sleek ponds or fountains, are designed to be visually striking and
              soothing, while integrated lighting enhances the landscape’s aesthetic during the
              evening.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-10 pt-20 bg-white md:flex-row lg:flex-row lg:justify-center lg:items-center">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic5.jpg"
            alt=""
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-24">
            <div className="flex-col text-2xl font-bold text-black md:text-4xl">
              <p>Morden landscape design </p>
            </div>

            <p className="text-[#878787] md:text-xl text-lg">
              All our professional landscape designers hold an experience of more than 30 years in
              different phrases of landscaping. Besides this, we completely understand that for a
              customer, his/her property is a very significant investment. Hence, we help our
              customers to accomplish all their dreams that they might be having for their property
              ranging from existing landscape construction to new construction landscapes for both
              commercial and residential projects. To start, we completely understand the ideas of
              our customers and further give suggestions from our end, and then we go ahead with a
              plan to execute the plan.
            </p>
            <p className="text-[#878787] md:text-xl text-lg">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex flex-wrap gap-2">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">VIDEO | </p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center bg-white ">
          <div className="container grid grid-cols-2 gap-3 lg:grid-cols-4 md:grid-cols-4 pt-14 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic6.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic6.jpeg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic7.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic7.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic8.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic8.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic9.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic9.webp'
                )
              }
            />
          </div>
        </div>
        <div className="container grid gap-5 pt-5 md:grid-cols-2 lg:pt-20 md:pt-10 ">
          <div className="flex flex-col md:items-center ">
            <div className="flex flex-col lg:w-[40%] md:w-[15rem] gap-10 ">
              <p className="text-2xl font-bold text-black md:text-4xl">Luxury Landspace Design </p>
            </div>
          </div>
          <div className="flex flex-col gap-2 text-[#878787] ">
            <div className="flex flex-col text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%]">
              <p>
                {' '}
                Design that reflects the client's lifestyle, such as outdoor sports courts, pools,
                or meditation gardens.
              </p>
              <p> Ashirwadum Architects & developers are leading architects for Landspace Design</p>
            </div>
            <p className="lg:w-[95%] md:w-[95%] md:p-2 md:text-xl text-lg ">
              Maintaining privacy without compromising on style is crucial. This can be achieved
              through custom-designed screens, walls, or dense hedges. Security features are often
              integrated seamlessly into the design, ensuring the safety of the space without
              disrupting its aesthetic. Lighting in a luxury landscape is carefully planned to
              create ambiance and highlight key features. This can include sophisticated outdoor
              lighting setups such as uplighting for trees, pathway lights, and strategically placed
              spotlights that enhance the nighttime beauty of the space.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-16 p-2 pt-5 md:grid-cols-2 lg:grid-cols-3 lg:pt-20 md:pt-10">
          <div className="flex flex-col gap-3 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic10.jpg"
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className="text-xl font-bold text-black ">FORMAL LANDSPACE DESIGN</p>
            <p className="text-[#878787] md:text-xl text-lg">
              A formal Landspace is a garden with a clear structure, geometric shapes and in most cases a symmetrical layout.
            </p>
            <p className="text-black">

              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO  | </strong>
              </Link>

              <Link to="/blog">

                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic11.jpg"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className="text-xl font-bold text-black ">TRADITIONAL LANDSCAPE DESIGN</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Traditional landscape design has maintained much of the grandeur of its origins, but has been adapted for contemporary urban life..
            </p>
            <p className="text-black ">
              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO | </strong>
              </Link>

              <Link to="/blog">

                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic12.avif"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className="text-xl font-bold text-black "> NATURALISTIC LANDSCAPE DESIGN</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Naturalistic landscape design is a style of garden design that's adapted to the local climate, geography, and hydrology.
            </p>
            <p className="text-black">

              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO | </strong>
              </Link>

              <Link to="/blog">

                <strong> BLOGS</strong>
              </Link>
            </p>
          </div>
        </div>

        <div className="flex items-center justify-center bg-white ">
          <div className="container grid grid-cols-2 gap-3 pt-5 md:grid-cols-4 lg:pt-20 md:pt-10 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic13.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic13.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic14.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic14.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic15.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic15.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic16.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic16.webp'
                )
              }
            />
          </div>
        </div>

        <div className="container flex flex-col pt-5 md:flex-row lg:gap-24 lg:pt-20 md:pt-10">
          <div className="flex flex-col gap-3 text-black justify-center md:w-[35%]  w-[100%]">
            <p className="text-2xl font-bold md:text-4xl">Ashirwadum Architects & developers</p>
            <p className="text-2xl font-bold md:text-4xl">Modern Houses</p>
            <p className="text-xl text-[#5d5c5b]">
              Architecture + Interior Design Modern style house design for villas, bungalows &
              farm-houses.
            </p>
            <Link to="/about">
              <button className="items-center justify-center w-32 mt-10 text-lg text-center border-4 border-black h-14 hover:bg-black hover:text-white">
                ABOUT
              </button>
            </Link>
            <Link to="/housing-design">
              <button className="w-48 mt-2 text-lg border-4 border-black h-14 hover:bg-black hover:text-white">
                CLASSICAL HOUSES
              </button>
            </Link>
          </div>
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic17.jpg"
            alt=""
            className="lg:w-[70rem] lg:h-[50vh] md:w-[50rem] md:h-[30vh] md:p-4 lg:p-0 w-[100%]"
          />
        </div>
        <div className="flex flex-col !w-full gap-10 pt-10 ">
          <div className="flex flex-col  !w-full gap-10">
            <p className="font-serif text-3xl font-bold text-center lg:text-4xl">
              Frequently Asked Questions
            </p>


            <div className='flex flex-col items-center justify-center'>
              <Accordion className="!w-[95%]">
                {faq?.map((faq, index) => (
                  <Accordion.Item eventKey={String(index)} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
                      }}
                    />
                  </Accordion.Item>
                ))}
              </Accordion>

            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default Landscapedesign
