import NotFound from 'NotFound'
import { AboutUs, ContactUs, Home } from 'Pages'
import Bestarchitectfirm from 'Pages/best-architect-firm'
import BestArchitechNoida from 'Pages/best-architect-firm/best-architech-noida'
import Faq from 'Pages/Faq'

import Insights from 'Pages/Insights'
import InsightsDetails from 'Pages/Insights/insightsdetails'

import Office from 'Pages/Office'
import Project from 'Pages/Projects'
import Commercialdesign from 'Pages/Services/Commercialdesign'
import House from 'Pages/Services/Housedesign/inedx'
import Institutionaldesign from 'Pages/Services/InstitutionalDesign'
import Interiordesign from 'Pages/Services/Interiordesign'
import Landscapedesign from 'Pages/Services/LandspaceDesign'
import Residentialdesign from 'Pages/Services/Residentialdesign'

import UrbanPlanning from 'Pages/Services/Urbanplanning'

/**
 * Array of route objects defining the routes for the application.
 * @type {Array<{
 *   id: number,
 *   path: string,
 *   element: JSX.Element,
 *   title: string,
 *   status: boolean
 * }>}
 */
const routes = [
  {
    id: 0,
    path: '*',
    element: <NotFound />,
    title: '404 Not Found',
    status: true,
  },
  {
    id: 1,
    path: '/',
    element: <Home />,
    title: 'Home',
    status: true,
  },
  {
    id: 2,
    path: '/about',
    element: <AboutUs />,
    title: 'About Us',
    status: true,
  },
  {
    id: 3,
    path: '/contact',
    element: <ContactUs />,
    title: 'Contact Us',
    status: true,
  },
  {
    id: 5,
    path: '/office',
    element: <Office />,
    title: 'office',
    status: true,
  },

  {
    id: 6,
    path: '/blog',
    element: <Insights />,
    title: 'insights',
    status: true,
  },

  {
    id: 7,
    path: '/blogs/:slug',
    element: <InsightsDetails />,
    title: 'Blog Details',
    status: true,
  },
  {
    id: 8,
    path: '/urban-planning',
    element: <UrbanPlanning />,
    title: 'Urban Planning',
    status: true,
  },
  {
    id: 9,
    path: '/landscape-design',
    element: <Landscapedesign />,
    title: 'Landscape Design',
    status: true,
  },
  {
    id: 10,
    path: '/housing-design',
    element: <House />,
    title: 'House',
    status: true,
  },
  {
    id: 11,
    path: 'commercial-design',
    element: <Commercialdesign />,
    title: 'Commercial Design',
    status: true,
  },

  {
    id: 13,
    path: 'residential-design',
    element: <Residentialdesign />,
    title: 'Residential Design',
    status: true,
  },
  {
    id: 14,
    path: 'institutional-design',
    element: <Institutionaldesign />,
    title: 'Institutional Design',
    status: true,
  },
  {
    id: 15,
    path: 'interior-design',
    element: <Interiordesign />,
    title: 'Interior Design',
    status: true,
  },
  {
    id: 16,
    path: '/projects',
    element: <Project />,
    title: 'Project',
    status: true,
  },
  {
    id: 17,
    path: '/best-architect-firm',
    element: <Bestarchitectfirm />,
    title: 'Bestarchitectfirm',
    status: true,
  },
  {
    id: 18,
    path: '/best-architects-noida',
    element: <BestArchitechNoida />,
    title: 'Bestarchitectfirm',
    status: true,
  },
  {
    id: 19,
    path: '/faq',
    element: <Faq />,
    title: 'Faq',
    status: true,
  },
]

export default routes
