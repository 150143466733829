import axios from "axios";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Avatar, Divider, TextField } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import axiosInstance from "Configs/axios";

const InsightsDetails = () => {
  const { slug } = useParams();
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [CommentsList, setCommentsList] = useState([])


  const insightsDetailsFn = async () => {
    try {
      const response = await axios.get(
        `https://m1blog.aaragroups.com/blog/blog-api/?slug=${slug}`,
        {
          headers: {
            Authorization: 'Token 108f496c431f17940641ad58f8c72bae292c80be',
          },
        }
      )

      if (response.data && response.data.data) {
        setData(response.data.data)
        console.log(response.data.data)
      } else {
        console.error('Unexpected response structure:', response)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    insightsDetailsFn()

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [slug])


  console.log("Post", data?.id);
  const formik = useFormik({
    initialValues: {
      user_name: "",
      email: "",
      website_url: "",
      comment: "",
    },
    onSubmit: async (values) => {
      const reqBody = {
        post_id: data?.id,
        name: values.user_name,  
        email: values.email,
        website_url: values.website_url,
        comment: values.comment,
        store_id: 8,
      };
      formik.resetForm();
      axiosInstance
        .post(
          /*  "http://192.168.1.188:9899/blog/post-comment-blog-api/", */
          `/blog/post-comment-blog-api/`,
          reqBody,
          {
            headers: {
              //Authorization: "c0951c5b80621b873302bc6d68669bdfe7876f73",
              Authorization: "Token e7b8852fe2ef05c740c18375cec4fbb722361f87",
              // "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          console.log(res);
          alert(res.data.message);
          // Toast(res.data.message);
        })
        .catch((err) => {
          console.log("ERROR: ====", err);
          alert(err.response.data.message);
        });
    },
  });


  const commentList = async (ids) => {

    const res = await axiosInstance.get(`/blog/comment-list-blog-api/`, {
      // store_id: id,
      params: { post_id: ids },
      headers: {
        //Authorization: "c0951c5b80621b873302bc6d68669bdfe7876f73",
        Authorization: "Token e7b8852fe2ef05c740c18375cec4fbb722361f87",
        "Access-Control-Allow-Origin": "*",
      },
    })
    setCommentsList(res.data.data);
    console.log("Blog Comments: ", res.data.data);

  };

  useEffect(() => {
    console.log("first", data?.id)
    data?.id && commentList(data?.id)

  }, [data?.id]);

  if (loading) {
    return <div className="flex items-center justify-center h-full">Loading...</div>
  }

  if (!data) {
    return <div className="flex items-center justify-center h-full">No data found</div>
  }
  console.log(data.title)






  return (
    <div className="bg-white">
      <Helmet key={''}>
        <title>{data?.title}</title>
        <meta name="description" content={data?.sub_title} />
        <meta name="keywords" content={data?.meta_keyword} />

      </Helmet>

      <div className="container  bg-white flex justify-center items-center !pt-10">
        <div className="flex flex-col gap-10 p-6 text-black bg-white">
          {data.images && (
            <LazyLoadImage
              src={data.images}
              alt={data.title}
              className="w-[100%]  object-cover"
            />
          )}
          <div className="flex flex-col">
            <h1 className="text-3xl font-bold">{data.title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: data.Content }}
              className="flex flex-col gap-5 p-2 mt-4 text-xl font-normal text-gray-800 lg:p-0"
            ></div>
          </div>
        </div>
      </div>
      <form
        className="container flex flex-col gap-5 p-6 "
        onSubmit={formik.handleSubmit}
      >

        <p className="text-2xl font-bold">{CommentsList?.length} Comments</p>
        {CommentsList?.map((comment) => (

          <>
            <span className="flex items-start gap-2 my-10" key={comment.id}>
              <Avatar src="broken" alt={comment.name} className="" />
              <span className="flex flex-col w-full gap-2">
                <span className="flex justify-between w-full">
                  <p className="font-bold">{comment.name}</p>
                  <p className="text-xs">{comment?.created_at?.slice(0, 10)}</p>
                </span>
                <p className="text-xs">{comment.comment}</p>
                <p className="text-sm "><strong className="text-base">Reply: </strong>{comment?.comment_replies[0]?.reply}</p>
              </span>
            </span>
            <Divider />
          </>

        ))}
        <p className="my-5 text-2xl font-bold">Leave A Comment</p>
        <TextField
          variant="outlined"
          label="Name"
          type="text"
          name="user_name"
          id="user_name"
          className="form-control"
          placeholder="Name"
          value={formik.values.user_name}
          onChange={formik.handleChange}
        />
        <TextField
          variant="outlined"
          label="Email"
          type="email"
          name="email"
          id="email"
          className="form-control"
          placeholder="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <TextField
          type="text"
          name="website_url"
          id="website_url"
          className="form-control"
          placeholder="Website"
          value={formik.values.website_url}
          onChange={formik.handleChange}
        />
        <TextField
          multiline
          rows={4}
          label="Comment"
          name="comment"
          id="comment"
          className="form-control"
          placeholder="Comment"
          value={formik.values.comment}
          onChange={formik.handleChange}
        />
        <button
          type="submit"
          className="w-fit border p-2 rounded-xl hover:bg-[#0d6dfc] hover:text-black bg-sky-500 text-white border-b-4 border-b-[#5942bd]"
        >
          Send Your Request
        </button>
      </form>
    </div>
  )
}

export default InsightsDetails
