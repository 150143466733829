import axios from 'axios'
import Modal from 'Components/Modal/modal'
import { baseURL } from 'Configs/axios'
import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

const BestArchitechNoida = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const [meta, setMeta] = useState()
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  const [faq, setFaq] = useState();

  const getFaq = () => {
    axios
      .get(`https://m1blog.aaragroups.com/blog/faq-api/`, {
        headers: {
          /* Authorization: `Token ${localStorage.getItem("token")}`, */
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          page_id: "8",
        },
      })
      .then((res) => {
        setFaq(res.data?.data);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };
  useEffect(() => {
    getFaq();

  }, []);
  const getMeta = () => {
    axios
      .get(`${baseURL}/blog/meta-api/?page_title=${14}`, {
        headers: {
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
        console.log(res?.data?.data);
      })

  };
  useEffect(() => {
    getMeta();

  }, []);
  return (
    <div className="flex flex-col items-center justify-center p-4 bg-white">
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (<Helmet>
        {/* <title>{meta.meta_title}</title> */}
        <meta name="title" content={metaItem?.meta_title || 'Default Title'} />
        <meta name="description" content={metaItem?.meta_description || 'Default description'} />
        <meta name="meta_tag" content={metaItem?.meta_tag || 'Default Tag'} />
        <meta name="meta_keyword" content={metaItem?.meta_keyword || 'Default Keyword'} />
        <meta property="og:title" content={metaItem?.og_title || 'Default Title'} />
        <meta property="og:description" content={metaItem?.og_description || 'Default description'} />
        <link rel='canonical' href={metaItem?.canonical} />
        <meta name="og_type" content={metaItem?.og_type || 'Default type'} />
        <meta name="og_url" content={metaItem?.og_url || 'Default description'} />
        <meta name="or_sitename" content={metaItem?.or_sitename || 'Default Tag'} />
        <meta name="twitter_card" content={metaItem?.twitter_card || 'Default card'} />
        <meta property="twitter_description" content={metaItem?.twitter_description || 'Default Title'} />
        <meta property="twitter_image" content={metaItem?.twitter_image || 'Default description'} />
        <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>

      </Helmet>))}
      <div className="container flex flex-col items-center justify-center">

        <iframe
          className="lg:h-[50vh] h-[25vh] w-full md:h-[35vh] lg:w-[90%] md:w-[90%] p-2"
          src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
          title="YouTube video player"
        ></iframe>
        <div className="flex flex-col gap-10 pt-10 lg:flex-row lg:pt-28">
          <div className="flex">
            <LazyLoadImage
              src="https://i0.wp.com/www.homebeautiful.com.au/wp-content/uploads/sites/2/umb-media/18163/300718-comfortable-sofa-9.jpg?w=1152&ssl=1"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/minakshi.jpeg')
              }
              alt=""
              className=" lg:h-[60vh] md:h-[35vh] h-[25vh] lg:w-[95%] w-[100%]"
            />
          </div>
          <div className="flex flex-col  lg:w-[60%] w-[100%]  p-2  pt-5  ">
            <p className="p-2 text-3xl font-bold text-black lg:text-4xl">
              Best Architects in NOIDA - Ashirwadum Architects & Developers
            </p>
            <p className="text-[#3b3b3a]  text-xl p-2 pt-10">
              Ashirwadum Architects & Developers is one of the{' '}
              <Link to="/">
                <strong className="text-orange-500 cursor-pointer hover:text-blue-500">
                  best architects in NOIDA{' '}
                </strong>
              </Link>{' '}
              , which is located at Sector 8, NOIDA. It is most popular and well-established
              architects in comparison of it's competitors, having built several high-profile
              commercial buildings in the city.
            </p>
            <p className="pt-3 text-xl font-bold text-black text-blck">
              We offer wide range of various Architect services:
            </p>
            <div className="p-2 pt-5 text-lg text-black">
              <div className="flex items-center gap-2">
                <p className="h-2 w-2 rounded-full bg-[#3b3b3a]"></p>
                <p className="flex gap-1">Residential Architect</p>
              </div>
              <div className="flex items-center gap-2">
                <p className="h-2 w-2 rounded-full bg-[#3b3b3a]"></p>
                <p>Commercial Architect</p>
              </div>

              <div className="flex items-center gap-2">
                <p className="h-2 w-2 rounded-full bg-[#3b3b3a]"></p>
                <p>Interior Designing</p>
              </div>

              <div className="flex items-center gap-2">
                <p className="h-2 w-2 rounded-full bg-[#3b3b3a]"></p>
                <p>Green Design Architect</p>
              </div>

              <div className="flex items-center gap-2">
                <p className="h-2 w-2 rounded-full bg-[#3b3b3a]"></p>
                <p>Landscape Architect</p>
              </div>

              <div className="flex items-center gap-2">
                <p className="h-2 w-2 rounded-full bg-[#3b3b3a]"></p>
                <p>Urban Designing</p>
              </div>

              <div className="flex items-center gap-2">
                <p className="h-2 w-2 rounded-full bg-[#3b3b3a]"></p>
                <p>Industrial Architect</p>
              </div>
            </div>
          </div>
        </div>
        <p className="p-2 text-3xl font-bold text-black lg:lg:text-4xl mt-14">
          Why Ashirwadum Architects & Developers is One of The Best Architects in NOIDA?
        </p>
        <div className="flex flex-col gap-10 pt-10 lg:flex-row lg:pt-20">
          <div className="flex flex-col  lg:w-[50%] w-[100%] text-[#5d5c5b] lg:px-0 lg:text-xl md:text-xl text-lg  p-2   ">
            <p className="p-2 ">
              Ashirwadum architects & Developers has been serving as one of the best architects in
              NOIDA for long time and is also known for its unique architecture and design. We, as a
              professional architect, have a very good reputation, being one of the few leading
              construction companies based in NOIDA.
            </p>
            <p className="p-2 ">
              Ashirwadum is the leading Architects & Developers firm that is offering a complete
              service to its clients. The organisation is a leader in the field of institutional and
              commercial projects.
            </p>
            <p className="p-2 ">
              Ashirwadum has a vast experience in the field of commercial, corporate, residential
              and landscape projects. The firm works on the basis of long-term and strategic
              partnership, with its clients and their architects. The Ashirwadum Architects is
              dedicated to provide complete Architect service to its clients in NOIDA
            </p>
            <p className="p-2">
              Ashirwadum is a renowned firm that offers an extensive range of services to its
              clients in NOIDA, Delhi NCR & Uttar Pradesh. The organisation has pulled off many
              commercial projects in NOIDA. Ashirwadum architects & developers provides different
              services to its clients according to their requirements.
            </p>
            <p className="p-2">
              Ashirwadum provides several different types of services to its clients. These services
              are required by the various types clients in different sectors. The services include
              office, residential, commercial, government, institutional and other building
              services. In fact, Ashirwadum offers its services to a variety of industries,
              including hotel sector, automobile sector, the real estate sector and others.
            </p>
          </div>
          <div className="flex ">
            <LazyLoadImage
              src="https://media.blogto.com/uploads/2022/04/12/1649792756-20220412-3201-highway-7-1.jpg?w=1400&cmd=resize&height=2500&quality=70"
              alt=""
              className=" lg:h-[60vh] md:h-[35vh] h-[25vh] lg:w-[100%] w-[100%]"
            />
          </div>
        </div>
        <div className="p-2  text-[#5d5c5b] lg:px-0 lg:text-xl md:text-xl text-lg pt-10">
          <p>
            Ashirwadum also provides an extensive network of expert consultants across the country.
            These consultants are responsible for providing various kinds of services to clients
            across the country. The consultants are responsible for handling all types of issues
            related to commercial, institutional and other projects. They are well experienced and
            knowledgeable about the projects and the requirements.
          </p>
          <p>
            Ashirwadum is responsible for designing various types of buildings for different
            sectors. We are one of the best architects in NOIDA expert in designing multiple
            building projects such as hotels, malls, office complexes, shopping complexes,
            apartments, corporate buildings and residential buildings. Ashirwadum has received
            numerous awards and recognition from several organizations over the years for working as
            the best architects in NOIDA. This recognition has enabled the firm to earn a good
            reputation.
          </p>
          <p>
            Ashirwadum is one of the most preferred architectural designing firms in the NOIDA &
            Delhi NCR and around the Uttar Pradesh. The firm is considered to be amongst the topmost
            architects and construction companies.{' '}
          </p>
          <p>
            {' '}
            Ashirwadum was established to design and build commercial buildings. Now, Ashirwadum has
            been established to design and build various types of commercial and residential and{' '}
            <Link to="https://en.wikipedia.org/wiki/Landscape_design">
              <strong className="text-orange-500 cursor-pointer hover:text-blue-500">
                landscape
              </strong>
            </Link>{' '}
            projects.
          </p>

          <p>
            Ashirwadum is also known to be one of the most reputed and highly talented architects in
            India. Ashirwadum architects & developers is known for it's creativity, passion, and
            ability in the field of architecture. The firm has been able to establish itself as a
            leader among other architecture and developers firms. Ashirwadum architects & developers
            is well known all around the globe for creative and innovative approach towards the
            field of commercial construction.
          </p>
          <p>
            Ashirwadum has many awards, recognitions, and recognition in the recent past. Ashirwadum
            architects & developers have achieved many milestones, achievements, and successes in
            the field of commercial construction in India.
          </p>
          <p>
            Ashirwadum has been successful in the field of building commercial and residential
            properties across the country. They have been able to build a strong reputation for
            themselves in the construction industry, and in the international market. Ashirwadum is
            known for its strong leadership in the area of commercial property.
          </p>
          <p>
            Ashirwadum is one of the leading architects in Noida, Uttar Pradesh. In the past few
            years, Ashirwadum has been able to expand its footprint across the country, and has
            managed to become one of the leading architectures and construction firms in Noida.
          </p>
        </div>
        <div className="flex flex-col !w-full gap-10 pt-10 ">
          <div className="flex flex-col  !w-full gap-10">
            <p className="font-serif text-3xl font-bold text-center lg:text-4xl">
              Frequently Asked Questions
            </p>


            <div className='flex flex-col items-center justify-center'>
              <Accordion className="!w-[95%]">
                {faq?.map((faq, index) => (
                  <Accordion.Item eventKey={String(index)} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
                      }}
                    />
                  </Accordion.Item>
                ))}
              </Accordion>
              {/*   <Accordion>
                {faq?.slice(4)?.map((faq, index) => (
                  <Accordion.Item eventKey={String(index + 4)} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body>
                      {faq.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion> */}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default BestArchitechNoida
