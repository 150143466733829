import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import Modal from 'Components/Modal/modal'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Accordion } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'
import { baseURL } from 'Configs/axios'

const AboutUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const [meta, setMeta] = useState();

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  const imgs = [
    {
      id: 1,
      images: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/about/slider1.jpg',
    },
    {
      id: 2,
      images: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/about/slider2.jpg',
    },
    {
      id: 3,
      images: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/about/slider3.jpg',
    },
  ]

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
  }

  const [faq, setFaq] = useState();

  const getFaq = () => {
    axios
      .get(`https://m1blog.aaragroups.com/blog/faq-api/`, {
        headers: {
          /* Authorization: `Token ${localStorage.getItem("token")}`, */
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          page_id: "12",
        },
      })
      .then((res) => {
        setFaq(res.data?.data);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };
  useEffect(() => {
    getFaq();
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseURL}/blog/meta-api/?page_title=${12}`, {
        headers: {
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
        console.log(res?.data?.data);
      })

  };
  useEffect(() => {
    getMeta();

  }, []);
  return (
    <div className="flex items-center justify-center bg-white ">
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
       {meta?.map((metaItem, index) => (<Helmet>

       {/*   <meta name="title" content={metaItem?.meta_title || 'Default Title'} /> */}
        <meta name="description" content={metaItem?.meta_description || 'Default description'} />
        <meta name="meta_tag" content={metaItem?.meta_tag || 'Default Tag'} />
        <meta name="meta_keyword" content={metaItem?.meta_keyword || 'Default Keyword'} />
        <meta property="og:title" content={metaItem?.og_title || 'Default Title'} />
        <meta property="og:description" content={metaItem?.og_description || 'Default description'} />
        <link rel='canonical' href={metaItem?.canonical} />
        <meta name="og_type" content={metaItem?.og_type || 'Default type'} />
        <meta name="og_url" content={metaItem?.og_url || 'Default description'} />
        <meta name="or_sitename" content={metaItem?.or_sitename || 'Default Tag'} />
        <meta name="twitter_card" content={metaItem?.twitter_card || 'Default card'} />
        <meta property="twitter_description" content={metaItem?.twitter_description || 'Default Title'} />
        <meta property="twitter_image" content={metaItem?.twitter_image || 'Default description'} />
        <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>

      </Helmet>))}
      <div className="container flex flex-col justify-center gap-5 p-4 bg-white lg:gap-14">
        <Slider {...settings}>
          {imgs.map((item) => (
            <div key={item.id} className="z-0 p-2">
              <LazyLoadImage
                src={item.images}
                alt={item.Title}
                className="w-full lg:h-[75vh] md:h-[50vh] h-[30vh]"
              />
            </div>
          ))}
        </Slider>
        <div className="flex flex-col justify-center pt-10 md:flex-row ">
          <div className="text-black text-4xl lg:w-[30%] w-[90%] flex flex-col gap-3">
            <p className="font-bold">Founders</p>
            <p className="text-xl text-black ">Meenakshi Tiwari</p>
          </div>
          <div className="flex md:w-[60%] w-[100%]   text-lg ">
            <p className="text-[#878787]">
              Ashirwadum Architects & developers are based in Lucknow and they provide services
              ranging from Urban planning, landscape designing, Housing design, Commercial design,
              residential design, institutional design and interior design.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-10 pt-5 lg:flex-row ">
          <div className="p-2">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/meeting.png"
              className="p-1 border-4 shadow-2xl"
              alt=""
            />
          </div>
          <div className="flex flex-col lg:w-[30%] w-[100%] gap-8">
            <p className="text-4xl font-bold text-black">Who are we?</p>
            <p className="text-[#878787] text-lg">
              Ashirwadum architects & developers are based out in the business district of Lucknow,
              India and they started their operation in 2009. Their goal is to come up with
              customized solutions in the architecture & interior design field. The organization has
              handled numerous projects of every size and during the course of its operation it has
              achieved massive success. Furthermore, they have a team of experienced professionals
              who cater to the unique requirement of the customer and in turn offer 100%
              satisfaction to them.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-10 lg:flex-row ">
          <div className="p-2">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/1.jpg"
              alt=""
            />
          </div>
          <div className="flex flex-col lg:w-[30%] w-[100%] gap-8">
            <p className="text-4xl font-bold text-black">Our Vision</p>
            <p className="text-[#878787] text-lg">
              Our vision is to deliver innovative and cost-effective architectural solutions in
              order to gain the reputation of our customers.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-8 lg:flex-row">
          <div className="text-blacek text-4xl lg:w-[30%] w-[100%] flex flex-col ">
            <p className="text-4xl font-bold text-black">Our mission</p>
          </div>
          <div className="flex flex-col lg:w-[50%] w-[100%] gap-5 ">
            <p className="text-[#878787] text-lg">
              Our mission involves providing top notch and safe solutions such that all our clients
              are happy with our work. In the process, we also want to be the best architectural
              company in India.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-3 lg:flex-row lg:gap-10">
          <div className="grid grid-cols-1 p-2 text-center md:grid-cols-2 ">
            <div className="flex flex-col items-center justify-center h-full md:p-4">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/minakshi.jpeg"
                alt="Co-Founder 1"
                className="w-full lg:h-[50vh] h-[35vh] border-4 p-1 shadow-2xl "
              />
              <p className="text-xl text-[#5d5c5b] mt-2">Founder</p>
            </div>
            <div className="flex flex-col items-center justify-center h-full md:p-4">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/amittiwari.jpeg"
                alt="Co-Founder 2"
                className="w-full lg:h-[50vh] h-[35vh]  border-4 p-1 shadow-2xl"
              />
              <p className="text-xl text-[#5d5c5b] mt-2">Co-Founder</p>
            </div>
          </div>
          <div className="flex flex-col lg:w-[30%] gap-8 justify-center ">
            <p className="text-4xl font-bold text-black">Our expert team</p>
            <p className="text-[#878787] text-lg">
              We are blessed to have an experienced team who completely understands the requirement
              of our customers and further leave no stone unturned to make them happy with our work.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 text-center lg:grid-cols-4 ">
          <div className="flex flex-col items-center justify-center bg-white ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/minakshi.jpeg"
              alt=""
              className="md:h-[30vh] h-[18vh] border-4 p-3"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/about/minakshi.jpeg')
              }
            />
            <p className="text-xl text-[#5d5c5b] mt-2  text-center">Founder & Architect</p>
            <p className="text-xl text-[#5d5c5b] mt-2  text-center">Meenakshi Tiwari</p>
          </div>
          <div className="flex flex-col items-center justify-center bg-white">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/amittiwari.jpeg"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/about/amittiwari.jpeg')
              }
              alt=""
              className="md:h-[30vh] h-[18vh] border-4 p-3"
            />
            <p className="text-xl text-[#5d5c5b] mt-2  flex flex-col  justify-center items-center text-center">
              Co-Founder
            </p>
            <p className="text-xl text-[#5d5c5b] mt-2  flex flex-col  justify-center items-center text-center">
              Amit Tiwari
            </p>
          </div>
          <div className="bg-white ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
              alt=""
              className="p-3 border-4 "
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
              }
            />
            <p className="text-xl text-[#5d5c5b] text-center  mt-2">Senior Designer</p>
          </div>
          <div className="flex flex-col items-center justify-center p-4 bg-white border-2 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
              alt=""
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
              }
            />
            <p className="text-xl text-[#5d5c5b] mt-2  text-centera">Finance Exec.</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 lg:grid-cols-4">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
            alt=""
            className="w-[100%] flex flex-col  bg-white p-4  border-2 justify-center  items-center"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
            alt=""
            className="w-[100%] flex flex-col justify-center  bg-white p-4  border-2 items-center"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
            alt=""
            className="w-[100%] flex flex-col justify-center  bg-white p-4  border-2 items-center"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
            alt=""
            className="w-[100%] flex flex-col justify-center  bg-white p-4  border-2  items-center "
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
            }
          />
        </div>
        <div className="flex flex-col justify-center gap-8 pt-10 lg:flex-row ">
          <div className="text-blacek text-4xl lg:w-[30%] w-[100%] flex flex-col ">
            <p className="text-4xl font-bold text-black">What do we do?</p>
          </div>
          <div className="flex flex-col lg:w-[50%] w-[100%] gap-5 ">
            <p className="text-[#878787] text-lg">
              Ashirwadum architects & developers offers different types of services to the customers
              ranging from Urban planning, landscape designing, Housing design, Commercial design,
              residential design, institutional design and interior design.
            </p>
          </div>
        </div>
        <div className="flex lg:flex-row md:!flex-row  flex-col justify-center items-center lg:pt-24 pt-10 md:p-2 w-[100%] ">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/book1.png"
            alt=""
            className="md:w-[50%] w-[70%] lg:h-[500px] md:h-[40vh] h-[30vh] lg:p-0 p-3  border-4 shadow-xl "
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/about/book1.png')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/book2.jpg"
            alt=""
            className="md:w-[70%] w-[100%]  lg:h-[500px] md:h-[40vh] h-[40vh] lg:p-0 p-3  border-4 shadow-xl"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/about/book2.jpg')
            }
          />
        </div>
        <div className="flex flex-col justify-center gap-8 pt-10 lg:flex-row ">
          <div className="text-blacek text-4xl lg:w-[40%] w-[100%] flex flex-col ">
            <p className="text-4xl font-bold text-black ">We Give You The Best</p>
          </div>
          <div className="flex flex-col lg:w-[50%] w-[100%] gap-5 ">
            <p className="text-[#878787] text-lg">
              Present all the speakers and participants in GenesisExpo`s beautiful layouts. Choose
              your favorite variant of layout and create your own. You can create also single
              speaker profile with all relevant information
            </p>
          </div>
        </div>
        <div className="container flex flex-col pt-10 md:flex-row lg:gap-24">
          <div className="flex flex-col gap-3 text-black justify-center md:w-[35%]  w-[100%]">
            <p className="text-2xl font-bold md:text-4xl"> </p>
            <p className="text-2xl font-bold md:text-4xl">Modern Houses</p>
            <p className="text-xl text-[#5d5c5b]">
              Architecture + Interior Design Modern style house design for villas, bungalows &
              farm-houses.
            </p>
            <Link to="/about">
              <button className="items-center justify-center w-32 mt-10 text-lg text-center border-4 border-black h-14 hover:bg-black hover:text-white">
                ABOUT
              </button>
            </Link>
            <Link to="/housing-design">
              <button className="w-48 mt-2 text-lg border-4 border-black h-14 hover:bg-black hover:text-white">
                CLASSICAL HOUSES
              </button>
            </Link>
          </div>
          <LazyLoadImage
            src="https://images.squarespace-cdn.com/content/v1/541eaa20e4b0eb59dead1cd7/6e2014a2-21c0-48ca-bbb8-f05491fc9ea8/Sketch%2BGraphic%2Bmodern.jpg?format=1500w"
            alt=""
            className="lg:w-[70rem] lg:h-[50vh] md:w-[50rem] md:h-[30vh] md:p-4 lg:p-0 w-[100%] "
          />
        </div>
        <div className="flex flex-col !w-full gap-10 pt-10 ">
          <div className="flex flex-col  !w-full gap-10">
            <p className="font-serif text-3xl font-bold text-center lg:text-4xl">
              Frequently Asked Questions
            </p>


            <div className='flex flex-col items-center justify-center'>
              <Accordion className="!w-[95%]">
                {faq?.map((faq, index) => (
                  <Accordion.Item eventKey={String(index)} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
                      }}
                    />
                  </Accordion.Item>
                ))}
              </Accordion>
              {/*   <Accordion>
                {faq?.slice(4)?.map((faq, index) => (
                  <Accordion.Item eventKey={String(index + 4)} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body>
                      {faq.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion> */}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>

  )
}

export default AboutUs
