import axios from 'axios'
export const baseURL = ' https://m1blog.aaragroups.com'
//export const baseURL = 'http://192.168.1.188:9899'

const createAxiosInstance = (baseURL) => {
  const instance = axios.create({ baseURL })

  instance.interceptors.request.use(
    (config) => {
      const Authorization = 'Token 108f496c431f17940641ad58f8c72bae292c80be'
      config.headers = { Authorization, ...config.headers }
      return config
    },

    (error) => Promise.reject(error)
  )

  instance.interceptors.response.use(
    (response) => response,

    (error) => Promise.reject(error)
  )

  return instance
}

const axiosInstance = createAxiosInstance(baseURL)

export default axiosInstance
