import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ArrowBackIos, ArrowForwardIos, DoubleArrow, ZoomIn } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'Components/Modal/modal'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Project = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const [zoomedImage, setZoomedImage] = useState(null)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [currentSlider, setCurrentSlider] = useState(null)

  const images = [
    {
      id: 1,
      src: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/projects/slider1.jpg',
      title: 'URBAN PLANNING',
      route: '/urban-planning',
      sliderType: 'urbanslider',
    },
    {
      id: 2,
      src: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/projects/slider2.jpg',
      title: 'LANDSCAPE DESIGN',
      route: '/landscape-design',
      sliderType: 'landspace',
    },
    {
      id: 3,
      src: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/projects/slider3.jpg',
      title: 'HOUSING DESIGN',
      route: '/housing-design',
      sliderType: 'housingslider',
    },
    {
      id: 4,
      src: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/projects/slider4.jpg',
      title: 'COMMERCIAL DESIGN',
      route: '/commercial-design',
      sliderType: 'commercialslider',
    },
    {
      id: 5,
      src: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/projects/slider5.jpg',
      title: 'RESIDENTIAL DESIGN',
      route: '/residential-design',
      sliderType: 'residentailslider',
    },
    {
      id: 6,
      src: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/projects/slider6.jpg',
      title: 'INSTITUTIONAL DESIGN',
      route: '/institutional-design',
      sliderType: 'institutionalslider',
    },
    {
      id: 7,
      src: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/projects/slider7.jpg',
      title: 'INTERIOR DESIGN',
      route: '/interior-design',
      sliderType: 'interiorslider',
    },
  ]

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const sliders = {
    urbanslider: [
      {
        id: 1,
        image:
          'https://ashirwadum.s3.ap-south-1.amazonaws.com/background/urban+design/03_SITE_PLAN.jpg',
      },
      {
        id: 2,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/background/urban+design/u1.jpg',
      },
      {
        id: 3,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/background/urban+design/new-u3.jpg',
      },
      {
        id: 4,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/background/urban+design/u2.webp',
      },
    ],
    landspace: [
      {
        id: 1,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic1.webp',
      },
      {
        id: 2,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic2.webp',
      },
      {
        id: 3,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic3.jpg',
      },
      {
        id: 4,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic4.jpg',
      },
    ],
    housingslider: [
      {
        id: 1,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic12.png',
      },
      {
        id: 2,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic13.jpg',
      },
      {
        id: 3,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic14.jpg',
      },
      {
        id: 4,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic15.jpeg',
      },
    ],
    commercialslider: [
      {
        id: 1,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/commercial-design/pic1.jpg',
      },
      {
        id: 2,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/commercial-design/pic2.jpg',
      },
      {
        id: 3,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/commercial-design/pic3.jpg',
      },
      {
        id: 4,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/commercial-design/pic4.jpg',
      },
    ],
    residentailslider: [
      {
        id: 1,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic13.jpg',
      },
      {
        id: 2,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic14.jpg',
      },
      {
        id: 3,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic15.jpg',
      },
      {
        id: 4,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic9.webp',
      },
    ],
    institutionalslider: [
      {
        id: 1,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic1.jpg',
      },
      {
        id: 2,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic2.jpg',
      },
      {
        id: 3,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic3.jpeg',
      },
      {
        id: 4,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic4.jpg',
      },
    ],
    interiorslider: [
      {
        id: 1,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/interior-design/pic13.jpg',
      },
      {
        id: 2,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/interior-design/pic14.webp',
      },
      {
        id: 3,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/interior-design/pic15.jpg',
      },
      {
        id: 4,
        image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/interior-design/pic16.png',
      },
    ],
  }

  const navigate = useNavigate()

  const handleReadMore = (route) => {
    navigate(route)
  }

  const handleZoomImage = (index, sliderType) => {
    const sliderData = sliders[sliderType]
    setZoomedImage(sliderData[index].image)
    setCurrentImageIndex(index)
    setCurrentSlider(sliderType)
  }

  const handleCloseZoom = () => {
    setZoomedImage(null)
    setCurrentSlider(null)
  }

  const handleNextImage = () => {
    const sliderData = sliders[currentSlider]
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sliderData.length)
    setZoomedImage(sliderData[(currentImageIndex + 1) % sliderData.length].image)
  }

  const handlePrevImage = () => {
    const sliderData = sliders[currentSlider]
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + sliderData.length) % sliderData.length)
    setZoomedImage(
      sliderData[(currentImageIndex - 1 + sliderData.length) % sliderData.length].image
    )
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="flex flex-col items-center justify-center p-4 bg-white ">
      <Helmet>
        <title>
          {' '}
          Ashirwadum architects provide best architectural and interior designing services
        </title>
      </Helmet>

      <div className="container flex flex-col items-center justify-center text-lg">
        <iframe
          src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
          title="Project Video"
          className="lg:h-[50vh] h-[25vh] w-full md:h-[35vh] lg:w-[90%] md:w-[90%] p-2"
        />
        <div className="flex flex-col gap-10 pt-20 bg-white md:flex-row lg:flex-row lg:justify-center lg:items-center ">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/projects/pic1.jpeg"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/projects/pic1.jpeg')
            }
            alt="Project Image"
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-full object-cover"
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-24">
            <div className="flex-col text-2xl font-bold text-black md:text-4xl">
              <p>Ashirwadum Architects & Developers Projects</p>
            </div>
            <p className="text-[#878787] md:text-xl text-lg">
              Ashirwadum Architects & Developers excels in creating innovative architectural designs
              and real estate developments. They offer a range of services from conceptual design to
              construction, focusing on both aesthetic appeal and functionality. Known for their
              commitment to quality and sustainability, they deliver projects that are both visually
              striking and environmentally responsible. Their portfolio showcases a blend of modern
              design with practical solutions, making them a respected name in the industry.
            </p>
            <p className="text-[#878787] md:text-xl text-lg">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">VIDEO |</p>
              </Link>
              <Link to="/blog">
                <p className="text-black"> BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center w-full pt-10 bg-white slider">
          <p className="mb-6 text-4xl font-bold text-black underline">All Projects</p>
          <div className="w-full">
            <Slider {...sliderSettings}>
              {images.map((image, index) => (
                <div key={image.id} className="relative md:p-4 group">
                  <div className="relative overflow-hidden">
                    <LazyLoadImage
                      src={image.src}
                      alt={`Project ${image.id}`}
                      className="w-full border-4 shadow-xl md:h-96 h-80 md:p-2 "
                    />
                    <div className="absolute inset-0 flex flex-col items-center justify-center transition-opacity duration-300 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100">
                      <p className="text-xl font-bold text-white">{image.title}</p>
                      <button
                        className="mt-2 text-white"
                        onClick={() => handleZoomImage(0, image.sliderType)}
                      >
                        <ZoomIn fontSize="large" />
                      </button>
                    </div>
                  </div>
                  <div className="p-3 text-xl font-bold text-center text-black">{image.title}</div>
                  <div className="flex items-center justify-center">
                    <button
                      className="flex text-lg text-red-600"
                      onClick={() => handleReadMore(image.route)}
                    >
                      READ MORE <DoubleArrow />
                    </button>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      {zoomedImage && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative">
            <button
              className="absolute w-12 h-12 text-3xl text-white bg-black bg-opacity-50 rounded-full top-1/2 left-5"
              onClick={handlePrevImage}
            >
              <ArrowBackIos />
            </button>
            <LazyLoadImage
              src={zoomedImage}
              alt="Zoomed"
              className="lg:w-[650px] lg:h-[40vh] md:h-[33vh] md:w-[450px]  h-[35vh] w-[350px] cursor-pointer"
              onClick={handleCloseZoom}
            />
            <button
              className="absolute w-12 h-12 text-3xl text-white bg-black bg-opacity-50 rounded-full top-1/2 right-5"
              onClick={handleNextImage}
            >
              <ArrowForwardIos />
            </button>
          </div>
        </div>
      )}
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default Project
