import { Divider } from '@mui/material'
import axios from 'axios'
import Modal from 'Components/Modal/modal'
import { baseURL } from 'Configs/axios'
import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

const UrbanPlanning = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const [meta, setMeta] = useState()
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const [faq, setFaq] = useState();

  const getFaq = () => {
    axios
      .get(`https://m1blog.aaragroups.com/blog/faq-api/`, {
        headers: {
          /* Authorization: `Token ${localStorage.getItem("token")}`, */
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          page_id: "16",
        },
      })
      .then((res) => {
        setFaq(res.data?.data);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };
  useEffect(() => {
    getFaq();

  }, []);
  const getMeta = () => {
    axios
      .get(`${baseURL}/blog/meta-api/?page_title=${16}`, {
        headers: {
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
        console.log(res?.data?.data);
      })

  };
  useEffect(() => {
    getMeta();

  }, []);
  return (
    <div className="flex items-center justify-center bg-white ">
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (<Helmet>
        {/*   <title>{meta.meta_title}</title> */}
        <meta name="title" content={metaItem?.meta_title || 'Default Title'} />
        <meta name="description" content={metaItem?.meta_description || 'Default description'} />
        <meta name="meta_tag" content={metaItem?.meta_tag || 'Default Tag'} />
        <meta name="meta_keyword" content={metaItem?.meta_keyword || 'Default Keyword'} />
        <meta property="og:title" content={metaItem?.og_title || 'Default Title'} />
        <meta property="og:description" content={metaItem?.og_description || 'Default description'} />
        <link rel='canonical' href={metaItem?.canonical} />
        <meta name="og_type" content={metaItem?.og_type || 'Default type'} />
        <meta name="og_url" content={metaItem?.og_url || 'Default description'} />
        <meta name="or_sitename" content={metaItem?.or_sitename || 'Default Tag'} />
        <meta name="twitter_card" content={metaItem?.twitter_card || 'Default card'} />
        <meta property="twitter_description" content={metaItem?.twitter_description || 'Default Title'} />
        <meta property="twitter_image" content={metaItem?.twitter_image || 'Default description'} />
        <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>

      </Helmet>))}
      <div className="container flex flex-col p-4 text-lg bg-white lg:justify-center">
        <div className="flex flex-col gap-10 pt-2 bg-white md:flex-row lg:flex-row lg:justify-center lg:items-center lg:pt-10">
          <iframe
            src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
            frameborder="0"></iframe>
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-2 justify-end lg:pt-0 md:pt-24">
            <p className="text-2xl font-bold text-black md:text-3xl lg:text-4xl">Urban planning</p>

            <p className="text-[#878787] text-lg ">
              Ashirwadum design & architects have significant hands-on experience in delivering
              urban planning projects as we have been serving this industry for a very long time. It
              includes Zonal planning, regional planning, Infrastructure planning, Master planning
              and special area development planning.
            </p>
            <p className="text-[#878787] text-lg ">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex flex-wrap gap-2 cursor-pointer">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">VIDEO |</p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center gap-5 bg-white">
          <div className="grid grid-cols-2 gap-5 pt-5 lg:grid-cols-4 md:grid-cols-4 lg:pt-14 md:pt-10">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic1.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic1.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic2.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic2.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic3.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic3.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic4.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic4.webp')
              }
            />
          </div>
        </div>
        <div className="container grid gap-2 pt-20 md:grid-cols-2 ">
          <div className="flex flex-col md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15%]  gap-10 ">
              <p className="text-2xl font-bold text-black md:text-4xl">
                Modern
                <br />
                Urban Planning
              </p>
            </div>
          </div>
          <div className="flex flex-col text-[#878787]  ">
            <p className="text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%] w-[75%]">
              Modern luxury house design for farmhouses & villas Architecture + Interior Design
            </p>

            <p className="lg:w-[95%] md:w-[95%] md:text-xl text-lg">
              Step into a realm where modern design seamlessly blurs the boundaries between indoors
              and nature, ushering in a truly holistic living experience.
            </p>
            <p className="text-lg md:text-xl">
              Our potential in urban planning is backed with original research in Innovation, Domain
              knowledge and sustainability all of which helps us to fulfil our goal to provide
              future cities that are economically feasible, socially acceptable and sustainable
              environmentally. Our team at Ashirwadum design & architects is equipped with
              professional planners who have extensive experience in different sectors such as
              social planning, Financial planning, Urban policy, Recreational planning, to name a
              few. Our work scope includes preparation of Base maps, City mapping, Infrastructure
              analysis, Socio- economic surveys, policy-level recommendations and discussion with
              various government agencies.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-10 pt-20 bg-white md:flex-row lg:flex-row lg:justify-center lg:items-center ">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic5.jpg"
            alt=""
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-24">
            <div className="flex-col text-2xl font-bold text-black md:text-4xl">
              <p> Modern Urban Planning </p>
            </div>

            <p className="text-[#878787] md:text-xl text-lg">
              Modern houses often showcase minimalist design, characterized by clean lines and
              simple forms. This aesthetic avoids unnecessary ornamentation, focusing instead on
              creating spaces that feel open and uncluttered. The design typically features open
              floor plans that unify living, dining, and kitchen areas, fostering a sense of
              spaciousness and fluid movement throughout the home.
            </p>
            <p className="text-[#878787] md:text-xl text-lg">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex flex-wrap gap-2 cursor-pointer">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">VIDEO |</p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center bg-white ">
          <div className="container grid grid-cols-2 gap-3 lg:grid-cols-4 md:grid-cols-4 pt-14 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic6.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic6.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic7.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic7.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic8.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic8.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic9.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic9.jpeg')
              }
            />
          </div>
        </div>
        <div className="container grid gap-5 pt-5 md:grid-cols-2 lg:pt-20 md:pt-10 ">
          <div className="flex flex-col md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15rem] gap-10 ">
              <p className="text-2xl font-bold text-black md:text-4xl">Luxury Urban Planning</p>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-[#878787] ">
            <div className="flex flex-col text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%]">
              <p>Contemporary & modern approach to interior design</p>
              <p> Ashirwadum Architects & developersare leading architects for home design</p>
            </div>
            <p className="lg:w-[95%] md:w-[95%] md:p-2 md:text-xl text-lg ">
              the firm's luxury urban planning approach is about creating environments that are not
              just visually stunning but also offer unparalleled comfort, convenience, and
              sophistication. Their work reflects a commitment to blending high-end design with
              innovative, sustainable practices, ensuring that each project stands as a testament to
              modern luxury living.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-10 p-2 pt-5 md:grid-cols-2 lg:grid-cols-3 lg:pt-20 md:pt-10">
          <div className="flex flex-col gap-3 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic10.png"
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className="text-xl font-bold text-black ">CITY PLANNER</p>
            <p className="text-[#878787] md:text-xl text-lg">
              city planner is responsible for assessing and planning the use of land, community areas and other urban locations.
            </p>
            <p className="text-black">

              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO | </strong>
              </Link>

              <Link to="/blog">

                <strong> BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic11.webp"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className="text-xl font-bold text-black ">LANDSCAPE ARCHITECT </p>
            <p className="text-[#878787] md:text-xl text-lg">
              A landscape architect is a person who is educated in the field of landscape architecture.
            </p>
            <p className="text-black">

              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO | </strong>
              </Link>

              <Link to="/blog">

                <strong> BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic12.jpeg"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className="text-xl font-bold text-black ">URBAN DESIGNER</p>
            <p className="text-[#878787] md:text-xl text-lg">
              An urban designer is a professional who creates the physical layout and design of cities, towns, and communities.
            </p>
            <p className="text-black">

              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO | </strong>
              </Link>

              <Link to="/blog">

                <strong> BLOGS</strong>
              </Link>
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center bg-white ">
          <div className="container grid grid-cols-2 gap-3 pt-5 md:grid-cols-4 lg:pt-14 md:pt-10">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic13.avif"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic13.avif'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic14.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic14.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic15.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic15.jpeg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic16.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic16.jpg')
              }
            />
          </div>
        </div>

        <div className="container flex flex-col pt-5 md:flex-row lg:gap-24 lg:pt-20 md:pt-10">
          <div className="flex flex-col gap-3 text-black justify-center md:w-[35%]  w-[100%]">
            <p className="text-2xl font-bold md:text-4xl">Ashirwadum Architects & developers</p>
            <p className="text-2xl font-bold md:text-4xl">Modern Houses</p>
            <p className="text-xl text-[#5d5c5b]">
              Architecture + Interior Design Modern style house design for villas, bungalows &
              farm-houses.
            </p>
            <Link to="/about">
              <button className="items-center justify-center w-32 mt-5 text-lg text-center border-4 border-black h-14 hover:bg-black hover:text-white">
                ABOUT
              </button>
            </Link>
            <Link to="/housing-design">
              <button className="w-48 mt-2 text-lg border-4 border-black h-14 hover:bg-black hover:text-white">
                CLASSICAL HOUSES
              </button>
            </Link>
          </div>
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic17.jpg"
            alt=""
            className="lg:w-[70rem] lg:h-[50vh] md:w-[50rem] md:h-[30vh] md:p-4 lg:p-0 w-[100%]"
          />
        </div>
        <div className="flex flex-col !w-full gap-10 pt-10 ">
          <div className="flex flex-col  !w-full gap-10">
            <p className="font-serif text-3xl font-bold text-center lg:text-4xl">
              Frequently Asked Questions
            </p>


            <div className='flex flex-col items-center justify-center'>
              <Accordion className="!w-[98%]">
                {faq?.map((faq, index) => (
                  <Accordion.Item eventKey={String(index)} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
                      }}
                    />
                  </Accordion.Item>
                ))}
              </Accordion>
              {/*   <Accordion>
                {faq?.slice(4)?.map((faq, index) => (
                  <Accordion.Item eventKey={String(index + 4)} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body>
                      {faq.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion> */}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default UrbanPlanning
