import { Divider } from '@mui/material'
import axios from 'axios'
import Modal from 'Components/Modal/modal'
import { baseURL } from 'Configs/axios'
import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

const Residentialdesign = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const [meta, setMeta] = useState()
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  const [faq, setFaq] = useState();

  const getFaq = () => {
    axios
      .get(`https://m1blog.aaragroups.com/blog/faq-api/`, {
        headers: {
          /* Authorization: `Token ${localStorage.getItem("token")}`, */
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          page_id: "20",
        },
      })
      .then((res) => {
        setFaq(res.data?.data);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };
  useEffect(() => {
    getFaq();
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseURL}/blog/meta-api/?page_title=${20}`, {
        headers: {
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
        console.log(res?.data?.data);
      })

  };
  useEffect(() => {
    getMeta();

  }, []);

  return (
    <div className="flex items-center justify-center bg-white ">
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (<Helmet>
        <title>{meta.meta_title}</title>
        <meta name="title" content={metaItem?.meta_title || 'Default Title'} />
        <meta name="description" content={metaItem?.meta_description || 'Default description'} />
        <meta name="meta_tag" content={metaItem?.meta_tag || 'Default Tag'} />
        <meta name="meta_keyword" content={metaItem?.meta_keyword || 'Default Keyword'} />
        <meta property="og:title" content={metaItem?.og_title || 'Default Title'} />
        <meta property="og:description" content={metaItem?.og_description || 'Default description'} />
        <link rel='canonical' href={metaItem?.canonical} />
        <meta name="og_type" content={metaItem?.og_type || 'Default type'} />
        <meta name="og_url" content={metaItem?.og_url || 'Default description'} />
        <meta name="or_sitename" content={metaItem?.or_sitename || 'Default Tag'} />
        <meta name="twitter_card" content={metaItem?.twitter_card || 'Default card'} />
        <meta property="twitter_description" content={metaItem?.twitter_description || 'Default Title'} />
        <meta property="twitter_image" content={metaItem?.twitter_image || 'Default description'} />
        <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>

      </Helmet>))}
      <div className="container flex flex-col p-4 bg-white lg:justify-center">
        <div className="flex flex-col gap-10 pt-2 text-lg bg-white md:flex-row lg:flex-row lg:justify-center lg:items-center lg:pt-10">
          <iframe
            src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
            frameborder="0"
          ></iframe>
          <div className="flex flex-col lg:w-[45%] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-24">
            <p className="text-2xl font-bold text-black md:text-3xl lg:text-4xl">
              Residential Design Services
            </p>

            <p className="text-[#878787] text-lg ">
              We at Ashirwadum architects & developers offer human-centered approach towards
              workspace design and Residential interiors.
            </p>
            <p className="text-[#878787] text-lg">
              It is backed with innovative finishes, space planning, textures, colors, surfaces and
              furniture. Furthermore, our experienced team approaches the project keeping a number
              of aspects in mind such as deadlines, budgets, market-driven research etc.{' '}
            </p>
            <p className="text-[#878787] text-lg ">
              Lastly, we ensure that our customers are delighted with our work.{' '}
            </p>
            <p className="text-[#878787] text-lg ">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex flex-wrap gap-2">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black"> VIDEO |</p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center gap-5 bg-white">
          <div className="container grid grid-cols-2 gap-4 lg:grid-cols-4 md:grid-cols-4 pt-14">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic1.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic1.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic2.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic2.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic3.png"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic3.png'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic4.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic4.jpg'
                )
              }
            />
          </div>
        </div>
        <div className="container grid gap-5 pt-20 md:grid-cols-2 ">
          <div className="flex flex-col md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15%]  gap-10 ">
              <p className="text-2xl font-bold text-black md:text-4xl">
                Modern
                <br />
                Residential Design
              </p>
            </div>
          </div>
          <div className="flex flex-col text-[#878787]  ">
            <p className="text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%] w-[75%]">
              Modern commerical design for farmhouses & villas Architecture + Interior Design
            </p>

            <p className="text-lg md:text-xl">
              modern Residential design is about creating environments that are not only visually
              appealing but also practical, adaptable, and aligned with contemporary values and
              technological advancements.
            </p>
          </div>
        </div>
        <Divider className="!mt-14 bg-gray-600 container "></Divider>
        <div className="flex flex-col gap-10 pt-2 bg-white md:flex-row lg:flex-row lg:justify-center lg:items-center lg:pt-12">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic5.jpg"
            onClick={() =>
              openModal(
                'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic5.jpg'
              )
            }
            alt=""
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-24">
            <div className="flex-col text-2xl font-bold text-black md:text-4xl">
              <p>Morden Residential Design </p>
            </div>

            <p className="text-[#878787] md:text-xl text-lg">
              Modern Residential design is all about creating functional, stylish, and efficient
              spaces tailored to business needs. It blends aesthetics with practicality, often
              featuring minimalist aesthetics, open layouts, and a focus on sustainability. Large
              windows and flexible, modular furniture are common, allowing for adaptable spaces that
              can evolve with changing needs. Technology integration is key, from smart lighting to
              interactive displays, enhancing both customer experience and operational efficiency.
            </p>
            <p className="text-[#878787] md:text-xl text-lg">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex flex-wrap gap-2">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black"> VIDEO |</p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center bg-white ">
          <div className="container grid grid-cols-2 gap-4 pt-5 lg:grid-cols-4 md:grid-cols-4 lg:pt-14 md:pt-10 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic6.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic6.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic7.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic7.jpeg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic8.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic8.jpeg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic9.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic9.webp'
                )
              }
            />
          </div>
        </div>
        <div className="container grid gap-5 pt-5 md:grid-cols-2 lg:pt-20 md:pt-10">
          <div className="flex flex-col md:items-center ">
            <div className="flex flex-col lg:w-[40%] md:w-[15rem] gap-10 ">
              <p className="text-2xl font-bold text-black md:text-4xl">
                Luxury Residential Design{' '}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-[#878787] ">
            <div className="flex flex-col text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%]">
              <p>
                {' '}
                Ashirwadum Architects & developers are leading architects for Residential Design
              </p>
            </div>
            <p className="lg:w-[95%] md:w-[95%] md:p-2 md:text-xl text-lg ">
              luxury Residential design is about creating spaces that offer unparalleled elegance
              and comfort while providing functional, high-end solutions that enhance the business's
              image and operations. It’s a balance of aesthetic splendor, superior materials, and
              innovative technology, aimed at delivering an extraordinary experience for clients,
              customers, and employees.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-16 p-2 pt-5 md:grid-cols-2 lg:grid-cols-3 lg:pt-20 md:pt-10 ">
          <div className="flex flex-col gap-3 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic10.jpg"
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic10.jpg'
                )
              }
            />
            <p className="text-xl font-bold text-black ">TRADITIONAL DESIGN</p>
            <p className="text-[#878787] md:text-xl text-lg">
              A traditional interior design scheme is timeless, placeless, comfortable, and put together but not overly fancy.
            </p>
            <p className="text-black">

              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO | </strong>
              </Link>
              <Link to="/blog">

                <strong> BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic11.jpg"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic11.jpg'
                )
              }
            />
            <p className="text-xl font-bold text-black ">MORDEN DESIGN</p>
            <p className="text-[#878787] md:text-xl text-lg">
              modern design puts a large emphasis on natural materials, keeping things in a more neutral color palette is key.
            </p>
            <p className="text-black ">

              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO | </strong>
              </Link>

              <Link to="/blog">

                <strong> BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic12.jpg"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic12.jpg'
                )
              }
            />
            <p className="text-xl font-bold text-black ">COTTAGE DESIGN</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Cottage house plans are designed to capture the essence of comfort, charm, and timeless appeal.
            </p>
            <p className="text-black">
              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO | </strong>
              </Link>
              <Link to="/blog">

                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
        </div>

        <div className="mt-20">
          <Divider className="container bg-gray-600 "></Divider>
        </div>

        <div className="flex items-center justify-center bg-white ">
          <div className="flex items-center justify-center bg-white ">
            <div className="container grid grid-cols-2 gap-4 pt-5 lg:grid-cols-4 md:grid-cols-4 lg:pt-14 md:pt-10 ">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic13.jpg"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic13.jpg'
                  )
                }
              />
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic14.jpg"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic14.jpg'
                  )
                }
              />
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic15.jpg"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic15.jpg'
                  )
                }
              />
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic16.avif"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic16.avif'
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="container flex flex-col pt-5 md:flex-row lg:gap-24 lg:pt-20 md:pt-10">
          <div className="flex flex-col gap-3 text-black justify-center md:w-[35%]  w-[100%]">
            <p className="text-2xl font-bold md:text-4xl">Ashirwadum Architects & developers</p>
            <p className="text-2xl font-bold md:text-4xl">Modern Houses</p>
            <p className="text-xl text-[#5d5c5b]">
              Architecture + Interior Design Modern style house design for villas, bungalows &
              farm-houses.
            </p>
            <Link to="/about">
              <button className="items-center justify-center w-32 mt-10 text-lg text-center border-4 border-black h-14 hover:bg-black hover:text-white">
                ABOUT
              </button>
            </Link>
            <Link to="/housing-design">
              <button className="w-48 mt-2 text-lg border-4 border-black h-14 hover:bg-black hover:text-white">
                CLASSICAL HOUSES
              </button>
            </Link>
          </div>
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic17.jpg"
            alt=""
            className="lg:w-[70rem] lg:h-[50vh] md:w-[50rem] md:h-[30vh] md:p-4 lg:p-0 w-[100%]"
            onClick={() =>
              openModal(
                'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic17.jpg'
              )
            }
          />
        </div>
        <div className="flex flex-col !w-full gap-10 pt-10 ">
          <div className="flex flex-col  !w-full gap-10">
            <p className="font-serif text-3xl font-bold text-center lg:text-4xl">
              Frequently Asked Questions
            </p>


            <div className='flex flex-col items-center justify-center'>
              <Accordion className="!w-[98%]">
                {faq?.map((faq, index) => (
                  <Accordion.Item eventKey={String(index)} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
                      }}
                    />
                  </Accordion.Item>
                ))}
              </Accordion>

            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default Residentialdesign
