import axios from 'axios'
import Modal from 'Components/Modal/modal'
import { baseURL } from 'Configs/axios'
import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

const House = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const [meta, setMeta] = useState()
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  const [faq, setFaq] = useState();

  const getFaq = () => {
    axios
      .get(`https://m1blog.aaragroups.com/blog/faq-api/`, {
        headers: {
          /* Authorization: `Token ${localStorage.getItem("token")}`, */
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          page_id: "18",
        },
      })
      .then((res) => {
        setFaq(res.data?.data);
        console.log(res.data?.data);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };
  useEffect(() => {
    getFaq();
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  const getMeta = () => {
    axios
      .get(`${baseURL}/blog/meta-api/?page_title=${18}`, {
        headers: {
          Authorization: "Token 108f496c431f17940641ad58f8c72bae292c80be",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
        console.log(res?.data?.data);
      })

  };
  useEffect(() => {
    getMeta();

  }, []);
  return (
    <div className="flex items-center justify-center p-4 bg-white ">
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (<Helmet>
        <title>{meta.meta_title}</title>
        <meta name="title" content={metaItem?.meta_title || 'Default Title'} />
        <meta name="description" content={metaItem?.meta_description || 'Default description'} />
        <meta name="meta_tag" content={metaItem?.meta_tag || 'Default Tag'} />
        <meta name="meta_keyword" content={metaItem?.meta_keyword || 'Default Keyword'} />
        <meta property="og:title" content={metaItem?.og_title || 'Default Title'} />
        <meta property="og:description" content={metaItem?.og_description || 'Default description'} />
        <link rel='canonical' href={metaItem?.canonical} />
        <meta name="og_type" content={metaItem?.og_type || 'Default type'} />
        <meta name="og_url" content={metaItem?.og_url || 'Default description'} />
        <meta name="or_sitename" content={metaItem?.or_sitename || 'Default Tag'} />
        <meta name="twitter_card" content={metaItem?.twitter_card || 'Default card'} />
        <meta property="twitter_description" content={metaItem?.twitter_description || 'Default Title'} />
        <meta property="twitter_image" content={metaItem?.twitter_image || 'Default description'} />
        <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>

      </Helmet>))}
      <div className="container flex flex-col justify-center gap-20 p-4 text-lg bg-white md:p-0">
        <div className="flex flex-col gap-10 pt-2 bg-white md:flex-row lg:flex-row lg:justify-center lg:items-center lg:pt-10">
          <iframe
            src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
            alt=""
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[20vh] w-[100%]"
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-20">
            <p className="text-2xl font-bold text-black md:text-3xl lg:text-4xl">House Design</p>

            <p className="text-[#878787] md:text-xl text-lg">
              The House is a family house for a multi-generational family which aims to embrace the
              cohesiveness of the family members which is held by a common value system just like a
              keystone. Visit the following links for more information about this Project .
            </p>

            <div className="flex gap-2">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">VIDEO | </p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-5 bg-white">
          <div className="container grid grid-cols-2 gap-3 pt-10 md:grid-cols-4">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic1.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic1.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic2.jpg"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic2.jpg')
              }
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic3.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic3.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic4.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic4.jpg')
              }
            />
          </div>
        </div>
        <div className="container grid gap-5 md:grid-cols-2 md:pt-20 ">
          <div className="flex flex-col md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15rem] gap-10 ">
              <p className="text-2xl font-bold text-black md:text-4xl">
                Classical
                <br />
                House Design
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-[#878787] ">
            <div className="flex flex-col text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%]">
              <p>
                Classic house design & classic interiors for farmhouses & villas. Design with a
                touch of enduring charm and gracefulness !
              </p>
            </div>
            <p className="lg:w-[95%] md:w-[90%] md:p-2 md:text-xl text-lg ">
              At the heart of our classical house designs lies a philosophy that values continuity
              and sophistication. We invite you to explore our portfoio and embark on a journey
              where architectural legacy meets the demands of the present, creating homes that stand
              as epitomes of classical elegance in the modern age.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-10 bg-white md:flex-row lg:justify-center lg:items-center md:pt-10">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic5.jpg"
            alt=""
            className="lg:h-[45vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[20vh] w-[100%]"
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-14 pt-5">
            <div className="flex-col text-2xl font-bold text-black md:text-4xl">

              <p>Classical House</p>
            </div>

            <p className="text-[#878787] lg:w-[25rem] md:w-[15rem] md:text-xl text-lg">
              The design of the house creates harmonious blend of timeless elegance and distinctive
              charm. From the intricate moldings and graceful arches to the expansive landscape,
              Each element is carefully curated to exude an aura of incomparable beauty and
              sophistication.{' '}
            </p>
            <p className="text-[#878787] lg:w-[25rem] md:w-[15rem] md:text-xl text-lg">
              Visit the following links for more information about this Project .
            </p>

            <div className="flex gap-2">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">VIDEO | </p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center bg-white ">
          <div className="container grid grid-cols-2 gap-4 pt-8 md:grid-cols-4 md:pt-10 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic6.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic6.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic7.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic7.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic8.avif"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic8.avif')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic9.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic9.jpg')
              }
            />
          </div>
        </div>

        <div className="container grid gap-5 pt-5 md:grid-cols-2 lg:pt-10 ">
          <div className="flex flex-col md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15rem] gap-10 ">
              <p className="text-2xl font-bold text-black md:text-4xl">
                Classical
                <br />
                Interior Design
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-[#878787] ">
            <div className="flex flex-col text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%]">
              <p>
                A timeless appeal that transcend fleeting trends Luxury classical interior design
              </p>
            </div>
            <p className="lg:w-[95%] md:w-[95%] md:p-2 md:text-xl text-lg ">
              Our passion lies in curating spaces that encapsulate timeless sophistication,
              meticulously weaving together the richness of historical aesthetics with the comforts
              of contemporary living. Every detail tells a story of refined artistry. Ornate
              moldings, exquisite chandeliers, and intricate carvings create an ambiance of grandeur
              that transcends eras.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-10 p-2 lg:grid-cols-3 md:grid-cols-2 md:pt-10 ">
          <div className="flex flex-col gap-3 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic10.jpg"
              className="lg:h-[45vh] md:h-[40vh] h-[40vh]"
            />
            <p className="text-xl font-bold text-black">CONTEMPORARY DESIGN</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Contemporary interior design is a diverse style, changing with new innovations and trends.
            </p>
            <p className="text-black">

              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO |</strong>
              </Link>

              <Link to="/blog">

                <strong> BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic12.jpg"
              alt=""
              className="lg:h-[45vh] md:h-[40vh] h-[40vh]"
            />
            <p className="text-xl font-bold text-black ">COTTAGE DESIGN</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Cottage house plans are designed to capture the essence of comfort, charm, and timeless appeal.
            </p>
            <p className="text-black">

              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO |</strong>
              </Link>

              <Link to="/blog">

                <strong> BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic13.jpg"
              alt=""
              className="lg:h-[45vh] md:h-[40vh] h-[40vh]"
            />
            <p className="text-xl font-bold text-black ">FARMHOUSE DESIGN</p>
            <p className="text-[#878787] md:text-xl text-lg">
              farmhouses are more about aesthetic beauty, affordability, relaxation and fulfilling dreams of one and all.
            </p>
            <p className="text-black">

              <Link to="https://www.youtube.com/@Ashirwadum">

                <strong>VIDEO |</strong>
              </Link>

              <Link to="/blog">

                <strong> BLOGS</strong>
              </Link>
            </p>
          </div>
        </div>

        <div className="flex items-center justify-center bg-white ">
          <div className="grid w-full grid-cols-2 gap-4 pt-5 md:grid-cols-4 md:pt-14 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic12.png"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic12.png')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic13.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic13.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic14.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic14.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic15.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic15.jpeg'
                )
              }
            />
          </div>
        </div>

        <div className="container flex flex-col md:flex-row lg:gap-24 md:pt-10 ">
          <div className="flex flex-col gap-3 text-black justify-center md:w-[35%]  w-[100%]">
            <p className="text-2xl font-bold md:text-4xl">Ashirwadum Architects & developers</p>
            <p className="text-2xl font-bold md:text-4xl">Modern Houses</p>
            <p className="text-xl text-[#5d5c5b]">
              Architecture + Interior Design Modern style house design for villas, bungalows &
              farm-houses.
            </p>
            <Link to="/about">
              <button className="items-center justify-center w-32 mt-10 text-lg text-center border-4 border-black h-14 hover:bg-black hover:text-white">
                ABOUT
              </button>
            </Link>
            <Link to="/housing-design">
              <button className="w-48 mt-2 text-lg border-4 border-black h-14 hover:bg-black hover:text-white">
                CLASSICAL HOUSES
              </button>
            </Link>
          </div>
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic16.jpg"
            alt=""
            className="lg:w-[70rem] lg:h-[50vh] md:w-[50rem] md:h-[30vh]  md:p-4 lg:p-0 w-[100%]"
          />
        </div>
        <div className="flex flex-col !w-full gap-10 pt-10 ">
          <div className="flex flex-col  !w-full gap-10">
            <p className="font-serif text-3xl font-bold text-center lg:text-4xl">
              Frequently Asked Questions
            </p>


            <div className='flex flex-col items-center justify-center'>
              <Accordion className="!w-[95%]">
                {faq?.map((faq, index) => (
                  <Accordion.Item eventKey={String(index)} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
                      }}
                    />
                  </Accordion.Item>
                ))}
              </Accordion>

            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default House
